import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const Breadcrumb = ({
  breadcrumbs = [
    {
      label: "Dashboard",
      link: "/dashboard",
    },
  ],
}) => {
  return (
    <div
      className=" p-3 inline-block   md:py-3 md:px-5 border-gray-300 rounded-full bg-white my-2 dark:bg-gray-800 dark:border-gray-700  shadow"
      aria-label="Breadcrumb"
      style={{ borderWidth: "1px" }}
      // style={{ zIndex: 15, position: "sticky", borderWidth: "1px" }}
    >
      <div className="flex items-center gap-2  ">
        {breadcrumbs.map((breadcrumb, i) => (
          <div className="flex items-center gap-2" key={i}>
            {i > 0 && <AiOutlineRight />}
            <Link
              to={breadcrumb.link}
              className="gap-1 flex  items-center whitespace-nowrap text-base font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              {breadcrumb.icon}{" "}
              <span className=" text-base">{breadcrumb.label}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
