const appConfig = {
  apiPrefix: "https://sssapi.brainware-india.net/",
  backendApiPrefix: "https://www.brainwareuniversity.ac.in/studentselfservice/",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
};

export default appConfig;
